import { createMarkup, getHtmlFromRaw } from "helpers/string";
import type { Client } from "types/model/client";

export const CookieNotice = ({
  client,
  setCookieChoice
}: {
  client: Client;
  setCookieChoice: (value: string) => void;
}) => {
  function handleAccept() {
    setCookieChoice("accepted");
    localStorage.setItem("cookieChoice", "accepted");
  }

  function handleReject() {
    setCookieChoice("rejected");
    localStorage.setItem("cookieChoice", "rejected");
  }

  return (
    <div className="fixed inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8">
      <div
        className="prose prose-sm max-w-4xl"
        dangerouslySetInnerHTML={createMarkup(
          getHtmlFromRaw(client.cookieNotice?.text)
        )}
      />
      <div className="flex flex-none items-center gap-x-5">
        <button
          type="button"
          className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          onClick={handleAccept}
        >
          Accept all
        </button>
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={handleReject}
        >
          Reject all
        </button>
      </div>
    </div>
  );
};
