import type { Schema } from "@data-driven-forms/react-form-renderer";
import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import { Dialog, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import {
  componentMapper,
  UserModalFormTemplate
} from "components/form/FormTemplate";
import { SubmitButton } from "components/form/SubmitButton";
import { generateCurrentUserFormSchema } from "helpers/form";
import useDeleteAuth from "hooks/useDeleteAuth";
import useFields from "hooks/useFields";
import type { UpdateUserDatailsData } from "hooks/useUpdateUserDetails";
import useUpdateUserDetails from "hooks/useUpdateUserDetails";
import { Fragment, useEffect, useRef, useState } from "react";
import { FieldUsage } from "types/constants";
import { FormTemplateType } from "types/form";
import type { Client } from "types/model/client";
import type { CurrentUser } from "types/model/user";

export const UpdateRequiredAccountDetailsModal = ({
  isOpen,
  currentUser,
  client
}: {
  isOpen: boolean;
  currentUser: CurrentUser;
  client: Client;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wrapperRef = useRef<any>(null);

  const [formSchema, setFormSchema] = useState<Schema>();

  const fieldsQueryInfo = useFields(FieldUsage.User, false, false);
  const [updateUserDetails, updateUserDetailsInfo] = useUpdateUserDetails();
  const [deleteAuth] = useDeleteAuth();

  useEffect(() => {
    if (currentUser && client && fieldsQueryInfo.data) {
      const { fields } = generateCurrentUserFormSchema({
        fieldsData: fieldsQueryInfo.data,
        user: currentUser,
        formTemplate: FormTemplateType.Seamless,
        client,
        fieldIds: currentUser.missingRequiredFieldIds
      });

      setFormSchema({ fields });
    }
  }, [currentUser, client, fieldsQueryInfo.data]);

  const onSubmit = async (formData: UpdateUserDatailsData): Promise<void> => {
    await updateUserDetails(formData);
  };

  const setOpen = () => {
    // console.log('setOpen');
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={wrapperRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative min-w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg">
                <div
                  className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                  ref={wrapperRef}
                >
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <UserCircleIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Account information required
                      </Dialog.Title>
                      <div className="mb-3 mt-2 text-left">
                        <p className="mb-5 text-sm text-gray-500 sm:mb-3">
                          Some additional information is required for your
                          account. Please provide the following information to
                          continue.
                        </p>
                        {formSchema && (
                          <FormRenderer
                            componentMapper={componentMapper}
                            FormTemplate={UserModalFormTemplate}
                            schema={formSchema}
                            clearedValue={null}
                            onSubmit={onSubmit}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <SubmitButton
                    buttonType="submit"
                    form="userForm"
                    disabled={updateUserDetailsInfo.isLoading}
                    isLoading={updateUserDetailsInfo.isLoading}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                  >
                    Save
                  </SubmitButton>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => deleteAuth()}
                  >
                    Log out
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
